<template>
  <v-container fluid class="content-wrapper mb-6">
    <div class="d-flex flex-row justify-start align-center mb-5">
      <h4 class="fw-600">{{ $t("SigninSetting.photo") }}</h4>
      <v-btn icon class="ml-3" @click="initAll()" :class="loadingData && 'animate__spining'" :disabled="loadingData">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </div>
    <v-row row wrap>
      <v-col cols="12">
        <div v-if="loadingData" class="card card-shadow pa-5">
          <div class="row">
            <v-col cols="12" sm="4" md="4" lg="4">
              <!-- <v-skeleton-loader type="paragraph"></v-skeleton-loader> -->
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex flex-row align-center justify-end">
              <!-- <v-skeleton-loader type="paragraph"></v-skeleton-loader> -->
              <v-skeleton-loader type="button"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="2" md="2" lg="2" v-for="n in 6" :key="n">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </div>
        </div>
        <div v-else-if="!loadingData && photoRoomTypes.length" class="card card-shadow pa-5">
          <template v-for="(item, index) in photoRoomTypes">
            <div :key="`image_${index}`" class="d-flex flex-column" style="height: 100%">
              <div class="d-flex flex-row justify-space-between align-center pa-3 px-0">
                <h3 class="title-sm">{{ item.typename }}</h3>
                <div>
                  <input
                    type="file"
                    accept="image/.jpg, .jpeg, .png"
                    multiple
                    :name="item._id"
                    :id="item._id"
                    class="imagefile"
                    @change="handleFileChange(...arguments, item)"
                  />
                  <label :for="item._id">
                    <v-icon left color="white">mdi-image-outline</v-icon>
                    {{ $t("SigninPhoto.btn_addnew") }}
                  </label>
                </div>
              </div>
              <draggable
                v-model="item.imageItems"
                draggable=".item"
                class="row"
                @change="moveImage"
              >
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  lg="2"
                  v-for="(image, index) in item.imageItems"
                  :key="image.name"
                  class="item d-flex flex-column justify-center align-center"
                >
                  <div style="width: 100%; min-height: 10rem; height: 100%">
                    <label v-if="image.name == primaryPictures" class="primary-image">{{
                      $t("SignupStep5.primary")
                    }}</label>
                    <!-- <label
                      v-if="
                        image.name !== primaryPictures &&
                        item.sort_number == 0 && 
                        item.category !== 'indoorandoutdoor'
                      "
                      class="cover-image"
                      >{{ $t("SignupStep5.cover") }}</label
                    > -->
                    <!-- <label
                      v-if="
                        image.sort_number == 0 && 
                        item.category !== 'indoorandoutdoor'
                      "
                      class="cover-image"
                      style="top: 2.8rem !important"
                      >{{ $t("SignupStep5.cover") }}</label
                    > -->
                    <label
                      v-if="
                        image.sort_number_total <= 1 &&
                        image.sort_number == 0 && 
                        item.category !== 'indoorandoutdoor'
                      "
                      class="cover-image"
                      >{{ $t("SignupStep5.cover") }}</label
                    >
                    <img
                      class="image"
                      @click="setNewPositionFunc(item, image)"
                      :src="image.src"
                      style="width: 100%; height: 100%; object-fit: cover"
                    />
                  </div>
                  <div class="d-flex flex-row">
                    <v-spacer />
                    <v-btn
                      :loading="item.loadingedit"
                      :disabled="item.loadingedit"
                      color="#6b6f82"
                      class="mt-1 mb-3"
                      @click="openEditPhoto(item, image)"
                      text
                    >
                      <v-icon left dark>mdi-pencil</v-icon>
                      <span class="textstyle">{{ $t("SigninPhoto.btn_edit") }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </draggable>
            </div>
            <v-divider v-if="index + 1 !== photoRoomTypes.length" :key="`divider_${index}`"></v-divider>
          </template>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="50rem" @keydown.esc="closeEditPhoto()">
      <v-card class="pa-3">
        <v-card-title class="d-flex flex-row align-center justify-start">
          <h3 class="title-sm mb-0">{{ $t("SigninPhoto.title_newtag") }}</h3>
          <v-spacer />
          <v-btn @click="closeEditPhoto()" icon>
            <v-icon dark x-small>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <v-row>
            <v-col cols="12" sm="12" md="5" lg="5" class="d-flex flex-column">
              <div
                v-if="dialogItem && dialogItem.category == 'indoorandoutdoor'"
                class="d-flex flex-column"
              >
                <div
                  class="d-flex flex-column overflow-y-auto mb-3"
                  style="max-height: 40vh"
                >
                  <template v-for="tagFac in dialogItem.facility_tags">
                    <v-btn
                      :key="tagFac._id"
                      color="blue-grey"
                      class="ma-2 white--text"
                      @click="removeFacilityTag(dialogItem, tagFac)"
                      text
                    >
                      {{ tagFac.value }}
                      <v-icon right dark>mdi-close-thick</v-icon>
                    </v-btn>
                  </template>
                </div>
                <div class="form-group">
                  <div
                    class="dropdown select"
                    @click="getfocus('facilitytags')"
                    @mouseleave="mouseOver('facilitytags')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="roomtype">
                      <v-autocomplete
                        v-model="selectFacilityTag"
                        :items="dialogItem.itemsFacilityTag"
                        item-text="name"
                        item-value="value"
                        label
                        :placeholder="$t('SigninPhoto.find_another_tag')"
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        @change="selectFacilityTagFunc"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content
                            @mouseenter="getfocus('facilitytags')"
                            @mouseleave="mouseOver('facilitytags')"
                          >
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </a>
                  </div>
                </div>
              </div>
              <div
                v-if="dialogItem && dialogItem.category != 'indoorandoutdoor'"
                class="d-flex flex-column"
              >
                <div
                  class="d-flex flex-column overflow-y-auto mb-3"
                  style="max-height: 40vh"
                >
                  <template v-for="tagAmenity in dialogItem.room_amenity_tags">
                    <v-btn
                      :key="tagAmenity._id"
                      color="blue-grey"
                      class="ma-2 white--text"
                      @click="removeAmenityTag(dialogItem, tagAmenity)"
                      text
                    >
                      {{ tagAmenity.name }}
                      <v-icon right dark>mdi-close-thick</v-icon>
                    </v-btn>
                  </template>
                </div>
                <div class="form-group">
                  <div
                    class="dropdown select"
                    @click="getfocus('facilitytags')"
                    @mouseleave="mouseOver('facilitytags')"
                  >
                    <a data-toggle="dropdown" data-display="static" id="roomtype">
                      <v-autocomplete
                        v-model="selectAmenityTag"
                        :items="dialogItem.itemsAmenityTag"
                        item-text="name"
                        item-value="value"
                        label
                        :placeholder="$t('SigninPhoto.find_another_tag')"
                        :no-data-text="$t('SignupStep.nodata')"
                        color="pink"
                        solo
                        flat
                        dense
                        single-line
                        hide-details
                        @change="selectAmenityTagFunc"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content
                            @mouseenter="getfocus('facilitytags')"
                            @mouseleave="mouseOver('facilitytags')"
                          >
                            <v-list-item-title
                              v-html="data.item.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </a>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7">
              <div style="min-height: 18rem; min-width: 18rem; object-fit: cover">
                <v-img
                  class="image"
                  v-if="dialogItem"
                  :lazy-src="dialogItem.chooseimage.src"
                  :src="dialogItem.chooseimage.src"
                ></v-img>
              </div>
              <div class="d-flex justify-center align-center mt-3">
                <v-btn
                  color="error"
                  dark
                  fab
                  x-small
                  depressed
                  @click="dialogDel(dialogItem.chooseimage)"
                  :disabled="!activeDel"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
              <div v-if="dialogItem" class="d-flex justify-start align-center">
                <v-checkbox
                  v-model="dialogItem.checkprimary"
                  :label="$t('SignupStep5.settoprimary')"
                  :disabled="dialogItem.checkprimary"
                  style="margin-bottom: 0 !important"
                  dense
                  hide-details
                  @change="dialogSetNewPrimary(dialogItem.chooseimage)"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import { EventBus } from "@/EventBus";
import _ from "lodash";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import draggable from "vuedraggable";

export default {
  name: "SigninPhotoScreen",
  metaInfo: {
    title: "Photo",
    titleTemplate: "%s | Aigent",
  },
  components: {
    draggable,
  },
  data: () => ({
    userToken: "",
    propertyID: "",
    propertyData: null,
    propertyPhotos: [],
    itemPropertyPhotos: [],
    itemsPhotoHave: [],
    primaryPictures: "",
    primaryPicturesAlbum: "",
    minImageWidth: 320,
    minImageHeight: 320,
    maxImageWidth: 1920,
    maxImageHeight: 1080,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 200,
      addRemoveLinks: false,
      maxFilesize: 1,
      maxFileSizeInMB: 1,
      maxFiles: 5,
      acceptedFiles: ".jpg, .jpeg, .png",
      // acceptedFiles: "image/*",
      init() {
        this.on("thumbnail", (file) => {
          if (file.width > this.maxImageWidth || file.height > this.maxImageHeight) {
            swal(
              "Image size is too large",
              "Image size should be no larger than 1920x1080 pixels",
              "warning",
              {
                button: false,
                timer: 3000,
              }
            );
          } else if (file.size > 1024 * 1024 * 1 /*1MB*/) {
            swal(
              "Image size is too large",
              "Image size should not exceed 1 MB",
              "warning",
              {
                button: false,
                timer: 3000,
              }
            );
          }
        });
      },
      complete: function (file) {
        this.removeFile(file);
      },
    },
    photoRoomTypes: [],
    dialog: false,
    dialogItem: null,
    itemsFacilityTag: [],
    selectFacilityTag: "",
    itemsRoomAmenityTag: [],
    selectAmenityTag: "",
    dialogUpdateItem: null,
    amountAllPictures: 0,
    itemSortPictures: [],
    activeDel: true,
    loadingData: false,
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    self.initAll();
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initAll);
  },
  watch: {
    photoRoomTypes() {
      const self = this;
      self.initSetPictures();
    },
    itemsPhotoHave() {
      const self = this;
      self.initSetPictures();
    },
  },
  methods: {
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document.getElementById(this.value_element).classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    initAll() {
      const self = this;
      self.loadingData = true
      self.photoRoomTypes = [];
      self.initPropertyData();
      self.initFacilityTags();
      self.initRoomAmenityTags();
      self.itemSortPictures = [];
      setTimeout(() => {
        if (self.photoRoomTypes.length) self.loadingData = false
      }, 500);
    },
    async initPropertyData() {
      const self = this;
      var temp = null;
      var tempCategory = [];
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API +
            `/property?_id=${self?.propertyID || self?.$route?.params?.propertyId}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          temp = res?.data?.result[0] || null;
          // console.log("temp: ", temp); 
          self.primaryPictures = res?.data?.result[0]?.cover_picture || "";
          self.primaryPicturesAlbum =
            res?.data?.result[0]?.pictures?.find(
              (p) => p.picture == res?.data?.result[0]?.cover_picture
            )?.category || "";
          if (temp?.pictures?.length) {
            var groupPictures = _.groupBy(temp?.pictures, "category");
            tempCategory = Object.keys(groupPictures).map((key) => {
              return {
                category: key,
                pictures: groupPictures[key].map((el) => el),
              };
            });
          }
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
      } finally {
        EventBus.$emit("endloading");
        self.propertyData = temp;
        self.propertyPhotos = temp?.pictures || [];
        // self?.propertyPhotos?.sort((a, b) =>
        //   a.sort_number > b.sort_number ? 1 : -1
        // );
        // console.log("itemsPhotoHave: ", tempCategory);
        self.itemsPhotoHave = tempCategory;
        self.setAlbumPictures(temp.rooms);
        EventBus.$emit("refreshAppbarProperty");
        // console.log("propertyPhotos: ", temp?.pictures);
      }
    },
    setAlbumPictures(roomNameData) {
      const self = this;
      self.itemPropertyPhotos = [];
      var item = [];
      var tempName = "";
      EventBus.$emit("loadingPage");
      self.itemPropertyPhotos.push({
        _id: "indoorandoutdoor",
        name: self.$t("SignupStep5.indoorandoutdoor") || "",
        typename: self.$t("SignupStep5.indoorandoutdoor") || "",
        imageKey: "indoorandoutdoor",
        imageItems: [],
        imageOptions: self.dropzoneOptions,
        primary_picture: "",
        datafileupload: null,
        fileSelecting: false,
        loadingedit: false,
      });
      roomNameData?.map((el) => {
        tempName = el._id;
        const temp = self?.itemPropertyPhotos?.find((p) => p._id == el._id);
        if (!temp?._id) {
          self.itemPropertyPhotos.push({
            _id: el._id,
            name: el?.room_name?.value || "",
            typename: el?.room_name?.value || "",
            imageKey: tempName,
            imageItems: [],
            imageOptions: self.dropzoneOptions,
            primary_picture: "",
            datafileupload: null,
            fileSelecting: false,
            loadingedit: false,
          });
        }
      });
      self.photoRoomTypes = self.itemPropertyPhotos;
    },
    initSetPictures() {
      const self = this;
      var tempRoomType = [];
      var num = 0;

      var sumPictures = 0;
      tempRoomType = self.photoRoomTypes;
      self.cangonextstep = false;
      if (self?.itemsPhotoHave?.length) {
        var tmpImage = null;
        tempRoomType.map((el) => {
          el.imageItems = [];
          tmpImage = self.itemsPhotoHave?.find((o) => o.category === el.imageKey);
          if (tmpImage?.category == "indoorandoutdoor") {
            if (tmpImage?.pictures?.length) self.cangonextstep = true;
            else self.cangonextstep = false;
          }
          if (tmpImage?.pictures?.length) {
            num = num + 1;
            var sumSort = 0;
            tmpImage?.pictures.map((ele, index) => {
              sumPictures++;
              if (ele.sort_number == 0) {
                sumSort++;
              }
              el.imageItems.push({
                _id: ele._id,
                name: ele.picture,
                src: `${process.env.VUE_APP_PIC_API}/${ele.picture}`,
                no: index,
                sort_number: ele.sort_number,
                sort_number_total: 0,
              });
            });
            el.imageItems.map((ele) => {
              var index = el.imageItems.findIndex((i) => i.no == ele.no);
              el.imageItems[index]['sort_number_total'] = sumSort;
            });
          }
          el.imageItems.sort((a, b) => (a.sort_number > b.sort_number ? 1 : -1));
          el.category = tmpImage?.category || "";
          el.primary_picture = tmpImage?.primary_picture || "";
        });
        // set album
        self.photoRoomTypes = tempRoomType;
      }
      self.amountAllPictures = sumPictures;
    },
    onButtonClick(item) {
      item.fileSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          item.fileSelecting = false;
        },
        { once: true }
      );

      this.$refs["datafileuploadid_" + item._id][0].click();
    },
    handleFileChange(file, item) {
      const self = this;
      var fileName = "";
      fileName = file.target.value;
      var files = file.target.files;
      var output = [];
      if (files?.length > 1)
        for (var i = 0, f; (f = files[i]); i++) {
          output.push(f);
          fileName = f?.name || "";
          if (fileName) self.updatePictures(f, item._id, item);
        }
      else {
        if (fileName) self.updatePictures(file.target.files[0], item._id, item);
      }
    },
    async updatePictures(file, key, item) {
      const self = this;
      var formData = new FormData();
      formData.append(`${key}`, file);
      const tempPhoto = self?.photoRoomTypes?.find(
        (p) => p.category == "indoorandoutdoor"
      );
      if (self.primaryPictures == "") {
        const obj = {
          cover_picture: file.name,
        };
        self.confirmSetPrimary(obj);
      }
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API + `/property/picture?property_id=${self.propertyID}`,
          formData,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        self.initAll();
        item.datafileupload = null;
        item.fileSelecting = false;
      }
    },
    setNewPositionFunc(element, image) {
      const self = this;

      swal(self.$t("SignupStep5.changepicto"), {
        buttons: {
          cancel: self.$t("SigninPolicy.btn_cancel"),
          primary: {
            text: self.$t("SignupStep5.primary"),
            value: "primary",
          },
          cover: self.primaryPictures !== image.name &&
            element.category !== "indoorandoutdoor" && {
              text: self.$t("SignupStep5.cover"),
              value: "cover",
            },
        },
      }).then((value) => {
        switch (value) {
          case "cancel":
            self.initAll();

          case "cover":
            const objCover = [
              {
                _id: image._id,
                sort_number: 0,
              },
            ];

            element?.imageItems?.map((el, i) => {
              if (el?._id !== image?._id)
                objCover.push({
                  _id: el._id,
                  sort_number: i + 1,
                });
            });
            self.confirmSetCover(objCover);
            break;

          case "primary":
            const objPrimary = {
              cover_picture: image.name,
            };
            self.confirmSetPrimary(objPrimary);
            break;
        }
      });
    },
    dialogSetNewPrimary(image) {
      const self = this;
      const obj = {
        cover_picture: image.name,
      };
      if (image.name) self.confirmSetPrimary(obj);
    },
    async confirmSetPrimary(obj) {
      const self = this;
      // console.log("obj: ", obj);
      EventBus.$emit("loadingtillend");

      // console.log("obj: ", obj);
      var tmpCategory = "";
      self?.photoRoomTypes?.map((el) => {
        // console.log("el: ", el);
        const filters = el?.imageItems?.find((p) => p?.name == obj?.cover_picture);
        if (filters?._id) tmpCategory = el?.category;
      });

      // console.log("tmpCategory: ", tmpCategory);

      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/cover_picture?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");

          var tempSet = [];
          self?.photoRoomTypes?.map((el) => {
            tempSet = [];
            el?.imageItems?.map((ele, index) => {
              if (ele.name == obj?.cover_picture) {
                tempSet.push({
                  name: ele?.name,
                  no: 0,
                  sort_number: 0,
                  src: ele?.src,
                  _id: ele?._id,
                });
              } else if (el?.category == tmpCategory) {
                tempSet.push({
                  name: ele?.name,
                  no: index + 1,
                  sort_number: index + 1,
                  src: ele?.src,
                  _id: ele?._id,
                });
              } else {
                tempSet.push({
                  name: ele?.name,
                  no: index,
                  sort_number: index,
                  src: ele?.src,
                  _id: ele?._id,
                });
              }
            });
            tempSet?.sort((a, b) => (a.sort_number > b.sort_number ? 1 : -1));
            // console.log("set: ", tempSet);
            self.movePictureFunc(tempSet);
          });
        } else {
          EventBus.$emit("endloading");
          swal("Warning", res?.message || "Please try again", "warning", {
            button: false,
            timer: 3000,
          });
        }
      } catch (error) {
        EventBus.$emit("endloading");
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      }
    },
    async confirmSetCover(obj) {
      const self = this;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/sort_picture?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    openEditPhoto(item, image) {
      const self = this;
      var tempFacility = [];
      var tempAmenity = [];
      var tempRoomAmenity = [];
      item.loadingedit = true;
      self.dialogItem = item;
      self.dialogItem.chooseimage = image;
      const temp = self.propertyPhotos.find((p) => p.picture == image.name);
      self.itemsFacilityTag.map((el) => {
        var select = temp?.facility_tags.find((p) => p._id == el._id);
        if (select == undefined)
          tempFacility.push({
            _id: el._id,
            name: el.name,
            value: el._id,
          });
      });
      self.dialogItem.itemsFacilityTag = tempFacility;
      self.dialogItem.facility_tags = temp?.facility_tags || [];
      self.dialogItem.checkprimary =
        item.chooseimage.name == self.primaryPictures ? true : false;
      self.itemsRoomAmenityTag.map((el) => {
        var select = temp?.room_amenity_tags.find((p) => p._id == el._id);
        if (select == undefined) {
          // tempRoomAmenity.push({
          //   _id: el._id,
          //   name: el.value,
          //   value: el._id,
          //   check_box: false,
          // });
          tempAmenity.push({
            _id: el._id,
            name: el.value,
            value: el._id,
          });
        } else
          tempRoomAmenity.push({
            _id: el._id,
            name: el.value,
            value: el._id,
            check_box: true,
          });
      });
      self.dialogItem.room_amenity_tags = tempRoomAmenity;
      self.dialogItem.itemsAmenityTag = tempAmenity;
      self.dialogItem.eachImage = temp;
      self.dialog = true;
    },
    closeEditPhoto() {
      const self = this;
      self.dialogItem.loadingedit = false;
      self.dialogItem = null;
      self.dialog = false;
      self.selectFacilityTag = "";
      self.selectAmenityTag = "";
    },
    async initFacilityTags() {
      const self = this;
      var temp = [];
      var tempAll = [];
      try {
        const res = await self.axios.get(process.env.VUE_APP_API + "/setting/facility", {
          headers: {
            Authorization:
              self?.userToken ||
              localStorage?.getItem("Token") ||
              self?.$store?.state?.resSignUpData?.access_token,
            "X-Language-Code":
              localStorage?.getItem("language") || self?.$store?.state?.language,
          },
        });
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            tempAll.push({
              _id: el._id,
              value: el._id,
              name: el.value,
            });
            if (el.select_box)
              temp.push({
                _id: el._id,
                value: el._id,
                name: el.value,
              });
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsFacilityTag = tempAll;
      }
    },
    async initRoomAmenityTags() {
      const self = this;
      var temp = [];
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/room_amenity",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            temp.push(el);
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsRoomAmenityTag = temp;
      }
    },
    async selectFacilityTagFunc() {
      EventBus.$emit("loadingtillend");
      const self = this;
      const indexItems = self?.photoRoomTypes?.findIndex(
        (p) => p._id == self.dialogItem?._id
      );
      self.dialogUpdateItem = self.dialogItem;
      // console.log("indexItems: ", indexItems);
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API +
            `/property/picture_tags?picture_id=${self.dialogItem.eachImage._id}&tags=${self.selectFacilityTag}&tags_type=facility_tags`,
          {},
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.initAll();
          // self.closeEditPhoto();
          self.dialogItem.loadingedit = false;
          self.dialogItem = null;
          self.selectFacilityTag = "";
          self.selectAmenityTag = "";
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        setTimeout(() => {
          self.openEditPhoto(self.dialogUpdateItem, self.dialogUpdateItem.chooseimage);
        }, 500);
        EventBus.$emit("endloading");
      }
    },
    async removeFacilityTag(dialogItem, tagFac) {
      const self = this;
      self.dialogUpdateItem = self.dialogItem;
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API +
            `/property/picture_tags?picture_id=${self.dialogItem.eachImage._id}&tags=${tagFac._id}&tags_type=facility_tags`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.initAll();
          self.dialogItem.loadingedit = false;
          self.dialogItem = null;
          self.selectFacilityTag = "";
          self.selectAmenityTag = "";
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        setTimeout(() => {
          self.openEditPhoto(self.dialogUpdateItem, self.dialogUpdateItem.chooseimage);
        }, 500);
      }
    },
    async selectAmenityTagFunc() {
      EventBus.$emit("loadingtillend");
      const self = this;
      self.dialogUpdateItem = self.dialogItem;
      try {
        const res = await self.axios.post(
          process.env.VUE_APP_API +
            `/property/picture_tags?picture_id=${self.dialogItem.eachImage._id}&tags=${self.selectAmenityTag}&tags_type=room_amenity_tags`,
          {},
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        // console.log(res)
        if (res.status == 200) {
          self.initAll();
          // self.closeEditPhoto();
          self.dialogItem.loadingedit = false;
          self.dialogItem = null;
          self.selectFacilityTag = "";
          self.selectAmenityTag = "";
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        setTimeout(() => {
          self.openEditPhoto(self.dialogUpdateItem, self.dialogUpdateItem.chooseimage);
        }, 500);
      }
    },
    async removeAmenityTag(dialogItem, tagAmenity) {
      EventBus.$emit("loadingtillend");
      const self = this;
      self.dialogUpdateItem = self.dialogItem;
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API +
            `/property/picture_tags?picture_id=${self.dialogItem.eachImage._id}&tags=${tagAmenity._id}&tags_type=room_amenity_tags`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.initAll();
          // self.closeEditPhoto();
          self.dialogItem.loadingedit = false;
          self.dialogItem = null;
          self.selectFacilityTag = "";
          self.selectAmenityTag = "";
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        setTimeout(() => {
          self.openEditPhoto(self.dialogUpdateItem, self.dialogUpdateItem.chooseimage);
        }, 500);
        EventBus.$emit("endloading");
      }
    },
    async changeRoomAmenityTagFunc(tag) {
      const self = this;
      self.dialogUpdateItem = self.dialogItem;
      if (tag.check_box) {
        try {
          const res = await self.axios.post(
            process.env.VUE_APP_API +
              `/property/picture_tags?picture_id=${self.dialogItem.eachImage._id}&tags=${tag._id}&tags_type=room_amenity_tags`,
            {},
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          if (res.status == 200) {
            self.initAll();
            self.closeEditPhoto();
          }
        } catch (error) {
          console.log(error?.response?.data?.message || error);
          swal(
            "Warning",
            error?.response?.data?.message || "Please try again",
            "warning",
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          self.openEditPhoto(self.dialogUpdateItem, self.dialogUpdateItem.chooseimage);
        }
      } else {
        try {
          const res = await self.axios.delete(
            process.env.VUE_APP_API +
              `/property/picture_tags?picture_id=${self.dialogItem.eachImage._id}&tags=${tag._id}`,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") || self?.$store?.state?.language,
              },
            }
          );
          if (res.status == 200) {
            self.initAll();
            self.closeEditPhoto();
          }
        } catch (error) {
          console.log(error?.response?.data?.message || error);
          swal(
            "Warning",
            error?.response?.data?.message || "Please try again",
            "warning",
            {
              button: false,
              timer: 3000,
            }
          );
        } finally {
          self.openEditPhoto(self.dialogUpdateItem, self.dialogUpdateItem.chooseimage);
        }
      }
    },
    async movePictureFunc(obj) {
      const self = this;
      // console.log("obj: ", obj);
      EventBus.$emit("loadingtillend");
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/sort_picture?property_id=${self.propertyID}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.initAll();
      }
    },
    moveImage(event) {
      const self = this;
      var obj = [];
      var temp = [];
      self?.photoRoomTypes?.map((el) => {
        const filters = el?.imageItems?.find((p) => p._id == event?.moved?.element?._id);
        if (filters?._id && el?.category !== "indoorandoutdoor") temp = el?.imageItems;
      });

      if (event?.moved?.element) {
        obj.push({
          _id: event?.moved?.element?._id,
          sort_number: event?.moved?.newIndex,
        });
        // self.movePictureFunc(temp);
      }
      self.movePictureFunc(temp);
    },
    async dialogDel(image) {
      const self = this;
      self.dialog = false;
      EventBus.$emit("loadingtillend");
      self.activeDel = false;
      try {
        const res = await self.axios.delete(
          process.env.VUE_APP_API +
            `/property/picture?property_id=${self.propertyID}&picture_name=${image.name}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") || self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) self.closeEditPhoto();
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal("Warning", error?.response?.data?.message || "Please try again", "warning", {
          button: false,
          timer: 3000,
        });
      } finally {
        EventBus.$emit("endloading");
        self.initAll();
        self.activeDel = true;
      }
    },
  },
};
</script>

<style scoped>
@import "../../../../styles/DragAndDrop.scss";
@import "../../../../styles/signin/roomdetails.css";
@import "../../../../styles/signin/holiday.css";
@import "../../../../styles/signin/rateplan.css";

.contain-album-name {
  border: 1px solid #000;
}

label {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
@import "../../../../styles/bootstrap/global.css";
@import "../../../../styles/signin/setting.css";

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.imagefile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.imagefile + label {
  font-size: 0.9em;
  // font-weight: 700;
  color: white;
  background-color: #666ee8 !important;
  display: inline-block;
  padding: 0.75rem 1rem !important;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imagefile:focus + label,
.imagefile + label:hover {
  background-color: #3a44e1 !important;
}
.imagefile + label {
  cursor: pointer; /* "hand" cursor */
}
.imagefile + label * {
  pointer-events: none;
}
.imagefile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.imagefile:focus + label,
.imagefile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.js .imagefile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.no-js .imagefile + label {
  display: none;
}

.primary-image {
  position: absolute;
  top: 1.2rem !important;
  left: 1.2rem !important;
  background-color: rgba(94, 52, 140, 0.7);
  padding: 0.313rem !important;
  border-radius: 1.563rem !important;
  font-size: 0.75rem;
  color: #fff;
  border: 0;
}

.cover-image {
  position: absolute;
  top: 1.2rem !important;
  left: 1.2rem !important;
  background-color: rgba(40, 95, 246, 0.7);
  padding: 0.313rem !important;
  border-radius: 1.563rem !important;
  font-size: 0.75rem;
  color: #fff;
  border: 0;
}
</style>
